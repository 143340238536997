'use client'

// React Imports
import { useEffect, useState, type CSSProperties } from 'react'

// Third-party Imports
import styled from '@emotion/styled'

// Component Imports
import MaterioLogo from '@core/svg/Logo'

// Config Imports
import themeConfig from '@configs/themeConfig'
import Image from "next/image";
import SDLLogo from '@/@core/svg/SDLLogo'
import useVerticalNav from '@/@menu/hooks/useVerticalNav'

type LogoTextProps = {
  color?: CSSProperties['color']
}

const LogoText = styled.span<LogoTextProps>`
  color: ${({ color }) => color ?? 'var(--mui-palette-text-primary)'};
  font-family: inherit;
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 600;
  letter-spacing: 0.15px;
  // text-transform: uppercase;
  margin-inline-start: 10px;
`

const Logo = ({ color }: { color?: CSSProperties['color'] }) => {
  const [companyData, setCompanyData] = useState<any>(JSON.parse(localStorage.getItem('companyData') || '[]'));
  const { isBreakpointReached, isToggled, toggleVerticalNav } = useVerticalNav()

  useEffect(() => {
    // if (typeof window !== 'undefined') {
    // if (localStorage.getItem('companyData') != 'undefined') {
    // alert(localStorage.getItem('userData'))
    setCompanyData(companyData)
    // }
    // }
    console.log("companyDatacompanyData", companyData?.name);

  }, [companyData]);

  return (
    <div className='flex items-center min-bs-[24px]'>
      {(companyData?.company_logo != undefined && companyData?.company_logo) ? (
        <Image src={companyData?.company_logo} alt="logo" width={200} height={-1} />
      ) : (
        // <Image src="/assets/images/logo.png" alt="logo" width={200} height={-1}/>
        <>
          <SDLLogo
            width={(isBreakpointReached)?65:68}
            height={(isBreakpointReached)?65:68}
          />
          <LogoText color={color} style={{ fontFamily: themeConfig.fontFamily, fontSize: (isBreakpointReached)?20:24,marginInlineStart:"5px !important" }}>{themeConfig.templateName}</LogoText>
        </>
      )}

      {/* <MaterioLogo className='text-[22px] text-primary'/>                
      <LogoText color={color}>{themeConfig.templateName}</LogoText> */}
    </div>
  )
}

export default Logo
