// React Imports
import type { SVGAttributes } from 'react'

import React from 'react';



const SDLLogo = ({ width, height }) => {


  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width={width/2.5} height={height/2.5} viewBox={`40 40 ${width} ${height}`} xmlSpace="preserve">
<g  >

	<path fillRule="evenodd" clipRule="evenodd" fill="#1777EF" d="M43.677,64.455c4.055,0,8.11,0.024,12.164-0.021
		c0.857-0.009,1.072,0.239,1.068,1.082c-0.033,7.463-0.021,14.927-0.019,22.391c0.001,3.597,1.767,5.368,5.344,5.37
		c7.356,0.005,14.712,0.025,22.067-0.004c0.877-0.003,1.153,0.198,1.143,1.116c-0.043,4.161-0.005,8.323,0.003,12.484
		c-0.143,0-0.287,0-0.43,0c-0.207-0.281-0.512-0.213-0.791-0.213c-12.375,0-24.749,0.004-37.123,0.007
		c-2.229-0.015-3.441-1.231-3.428-3.024c0-0.144,0-0.287,0-0.431c0.353-0.317,0.209-0.738,0.209-1.114
		c0.007-12.033,0.007-24.065,0-36.098c0-0.376,0.143-0.797-0.209-1.115C43.677,64.742,43.677,64.599,43.677,64.455z"/>
	
	<path fillRule="evenodd" clipRule="evenodd" fill="#28D06A" d="M90.832,106.873c0.004-4.158,0.031-8.317-0.006-12.475
		c-0.008-0.85,0.151-1.18,1.096-1.166c3.728,0.056,7.457,0.046,11.185,0.005c0.82-0.009,1.093,0.179,1.083,1.053
		c-0.044,4.194-0.013,8.388-0.008,12.583C99.731,106.873,95.281,106.873,90.832,106.873z"/>
	<path fillRule="evenodd" clipRule="evenodd" fill="#FA6265" d="M57.026,45.507c-0.024,1.97-0.051,3.939-0.072,5.909
		c-0.023,2.188-0.071,4.375-0.045,6.562c0.009,0.752-0.22,0.98-0.974,0.978c-3.694-0.015-7.388,0.023-11.083,0.048
		c-0.392,0.003-0.784,0.045-1.176,0.068c0-4.45,0-8.9,0-13.35c3.933-0.004,7.867-0.004,11.8-0.019
		c0.377-0.001,0.794,0.138,1.119-0.197C56.739,45.507,56.883,45.507,57.026,45.507z"/>
	
	<path fillRule="evenodd" clipRule="evenodd" fill="#1877EF" d="M62.202,45.726c12.771,0,25.541,0,38.311,0.001
		c2.456,0.766,3.64,2.356,3.641,4.92c0.002,12.049-0.009,24.099,0.021,36.148c0.002,0.887-0.211,1.145-1.117,1.132
		c-3.693-0.053-7.388-0.062-11.081,0.004c-0.991,0.018-1.165-0.299-1.16-1.212c0.038-7.531,0.022-15.062,0.021-22.593
		c-0.001-3.338-1.782-5.136-5.095-5.137c-7.495-0.002-14.99-0.017-22.485,0.021c-0.904,0.005-1.14-0.241-1.126-1.132
		C62.196,53.828,62.186,49.776,62.202,45.726z"/>
	
	<path fillRule="evenodd" clipRule="evenodd" fill="#DDCE40" d="M62.159,76.1c0-3.552,0.022-7.104-0.017-10.655
		c-0.009-0.789,0.188-1.039,1.012-1.035c7.103,0.033,14.207,0.029,21.31,0.004c0.735-0.002,0.983,0.186,0.98,0.955
		c-0.027,7.175-0.026,14.35-0.002,21.525c0.002,0.741-0.202,0.998-0.967,0.995c-7.139-0.023-14.278-0.022-21.417-0.001
		c-0.703,0.002-0.92-0.206-0.914-0.918C62.177,83.347,62.159,79.723,62.159,76.1z"/>
	
</g>
</svg>
  );
};


export default SDLLogo
